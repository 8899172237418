// MediumBlog.js
import React, { useEffect, useState } from 'react';

const MediumBlog = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {

    const fetchMediumPosts = async () => {
      try {
        const response = await fetch(
          `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@aleksei.aleinikov.gr`
        );
        const data = await response.json();
        const latestPosts = data.items.slice(0, 5);
        setPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching Medium posts:', error);
      }
    };

    fetchMediumPosts();
  }, []);

  return (
    <div className="featured-blog">
      <h3>FEATURED ON MY BLOG</h3>
      <ul>
        {posts.map((post) => (
          <li key={post.guid}>
            <a href={post.link} target="_blank" rel="noopener noreferrer">
              {post.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MediumBlog;

// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Styles/Home.css';
import Sidebar from './components/Sidebar';

const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="main-content">
        <div className="intro">
          <h2>Hi, I'm Aleksei.</h2>
          <p>
            A <strong>Cloud DevSecOps Engineer</strong> and <strong>Full-Stack Developer</strong>  with <strong>10+ years of experience</strong> in designing, securing, and automating cloud-native infrastructures  
            and software solutions.
          </p>

          <div className="buttons">
            <button><Link to="/offer">Offer</Link></button>
            <button><Link to="/portfolio">Portfolio</Link></button>
            <button><Link to="/cv">CV</Link></button>
            <button><Link to="/contact">Contact Me</Link></button>
          </div>
        </div>
      </div>
    </div>
  ); 

};


export default Home;


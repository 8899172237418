// HireMe.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../Styles/Hireme.css';

const HireMe = () => {
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  useEffect(() => {
    const pageTimer = setTimeout(() => setIsPageVisible(true), 100);
    const infoTimer = setTimeout(() => setIsInfoVisible(true), 200);

    return () => {
      clearTimeout(pageTimer);
      clearTimeout(infoTimer);
    };
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className={`Hireme-content fade-in-page ${isPageVisible ? 'active' : ''}`}>
      <h2>Hire Me</h2>
      <p>
  I’m excited to announce that I’m <span className="highlight">looking for new challenges</span> where I can apply my expertise in  
  <strong> Cloud Solutions</strong>, <strong>DevSecOps</strong>, and <strong>Full-Stack Development</strong> to build impactful, scalable, and resilient systems.
</p>
<p>
  Over the past <strong>10 years</strong>, I’ve worked extensively with cloud platforms like AWS, GCP, and Azure, 
  automating infrastructure, optimizing cloud performance, and ensuring high availability for mission-critical applications. 
  I specialize in <strong>Kubernetes orchestration, Terraform automation, and CI/CD pipelines</strong>, enabling businesses to 
  achieve <span className="highlight">99.99% uptime</span> and seamless cloud operations.
</p>
<p>
  If you’re looking for someone who can enhance your cloud architecture, optimize DevOps workflows, 
  and implement scalable full-stack solutions, I’d love to hear from you. Let’s build something reliable and future-proof together!
</p>

        <div className="contact-button">
          <Link to="/contact">
            <button className="contact-link-button">Go to Contact Form</button>
          </Link>
        </div>
        <div className={`contact-info fade-in-element ${isInfoVisible ? 'active' : ''}`}>
          <ul>
            <li>
              <span className="icon linkedin-icon"></span>
              <a
                href="https://www.linkedin.com/in/aleksei-aleinikov-78195911a/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <span className="icon github-icon"></span>
              <a
                href="https://github.com/LF3551"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HireMe;
